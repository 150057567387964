import React from 'react'
import { Link } from 'gatsby'
import './style.scss'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faCoffee,
  faSkiing,
  faEnvelope,
  faChartLine,
  faClipboardCheck,
  faNewspaper,
  faGraduationCap,
  faChartBar,
  faUserTie,
  faRss,
  faPen,
  faFileAlt,
  faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faCoffee,
  faSkiing,
  faEnvelope,
  faChartLine,
  faClipboardCheck,
  faNewspaper,
  faGraduationCap,
  faChartBar,
  faUserTie,
  faRss,
  faPen,
  faFileAlt,
  faEnvelopeOpenText,
)

class Menu extends React.Component {
  render() {
    const menu = this.props.data
    console.log(menu)
    const menuBlock = (
      <ul className="menu__list">
        {menu.map(item => (
          <li className="menu__list-item" key={item.path}>
            <div className="menu__list-item-icons">
              <FontAwesomeIcon
                className="menu__list-item-icons-icon"
                icon={item.icon}
              />
            </div>
            <div className="menu__list-item-links">
              <Link
                to={item.path}
                className="menu__list-item-links-link"
                activeClassName="menu__list-item-links-link menu__list-item-links-link--active"
              >
                {item.label}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    )

    return <nav className="menu">{menuBlock}</nav>
  }
}

export default Menu
