import React from 'react'
import './style.scss'
import '../../assets/fonts/fontello-771c82e0/css/fontello.css'
import '../../assets/fonts/JetBrainsMono/css/JetBrainsMono.css'
// import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  fab,
  faLinkedinIn,
  faTwitter,
  faGithub,
  faTwitch,
  faCodepen,
} from '@fortawesome/free-brands-svg-icons'

library.add(fab, faLinkedinIn, faTwitter, faGithub, faTwitch, faCodepen)

const Links = ({ data }) => {
  console.log(data)

  return (
    <div className="links">
      <ul className="links__list">
        {data.map(item => (
          <li key={item.social} className="links__list-item">
            <a
              href={`${item.socialUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ width: '1em' }}
                icon={['fab', item.socialIcon]}
              />
            </a>
          </li>
        ))}
        {/* <li className="links__list-item">
          <a
            href={`${data.linkedin}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon style={{ width: '1em' }} icon={faLinkedinIn} />
          </a>
        </li>

        <li className="links__list-item">
          <a href={`${data.twitter}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon style={{ width: '1em' }} icon={faTwitter} />
          </a>
        </li>
        <li className="links__list-item">
          <a href={`${data.github}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon style={{ width: '1em' }} icon={faGithub} />
          </a>
        </li>
        <li className="links__list-item">
          <a href={`${data.twitch}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon style={{ width: '1em' }} icon={faTwitch} />
          </a>
        </li>
        <li className="links__list-item">
          <a href={`${data.codepen}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon style={{ width: '1em' }} icon={faCodepen} />
          </a>
        </li> */}
      </ul>
    </div>
  )
}

export default Links
